.mint-list {
    list-style-image: url(../../assets/icons/bullet.svg);
    list-style-position: inside;
    color: #63342E;
    font-size: 23px;
    line-height: 60px;
    font-weight: 500;
    padding: 10px 0 10px 20px;
    text-indent: -1em;
}



.mint {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mint-1 {
    background-repeat: no-repeat;
    /* padding-left: 3vw;
    padding-right: 3vw; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url(http://localhost:3000/static/media/contactimg.be8b526ebc8225ab37b5.svg);
    background-size: cover;
    margin-top: -100px;
    padding-top: 100px;
    margin-left: auto;
    margin-right: auto;
    max-width: 2000px;
}

.mint-h1 {
    font-size: 150px;
    color: #F0484B;
    font-family: 'Marlissa';
    z-index: 2;
    margin-top: -5px;
}

.mint-h2 {
    font-size: 120px;
    color: #63342E;
    margin-top: -85px;
    z-index: 1;
    font-weight: bold;
}

.mint-img1 {
    margin-top: -180px;
    height: 50rem;
}

.mint-itr {
    display: flex;
    flex-direction: column;
    padding-top: 120px;
    padding-left: 10vw;
    padding-right: 10vw;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 2000px;
    display: flex;
}



@media (max-width:900px) {
    .mint-h1 {
        font-size: 15vw !important;
    }

    .mint-h2 {
        font-size: 15vw !important;
        margin-top: -12vw !important
    }

    .mint-list {
        font-size: 16px !important;
        line-height: 35px !important;
    }
}