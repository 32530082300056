.footer-contact {
    max-width: 2000px;
    margin-left: auto;
    margin-right: auto;
    padding: 5% 5% 2% 5%;
    background: #FEE6D7;
}

.footer-icon-contact {
    margin-left: auto;
    margin-right: auto;
}

.footer-social-contact {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    column-gap: 20px;
}

.footer-social-contact>img {
    height: 30px;
    color: #FEE6D7;
}

.footer-last-contact {
    display: flex;
    justify-content: center;
    column-gap: 20px;
    margin-top: 30px;
    color: #64362E;
}