.footer {
    padding: 5% 5% 2% 5%;
    max-width: 2000px;
    margin-left: auto;
    margin-right: auto;
    background: transparent linear-gradient(0deg, #210B08 0%, #2A100D 36%, #5D2E28 100%) 0% 0% no-repeat padding-box;

}

.footer-icon {
    margin-left: auto;
    margin-right: auto;
}

.footer-social {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    column-gap: 20px;
}

.footer-social>img {
    height: 30px;
    color: #FEE6D7;
}

.footer-last {
    display: flex;
    justify-content: center;
    column-gap: 20px;
    margin-top: 30px;
    color: white;
}