.charity {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    display: flex;
    padding-left: 3vw;
    max-width: 2000px;
    /* margin-top: -100px; */
}

.charityLeft {

    display: flex;
    flex-direction: column;
    flex: 0.5;
    margin-top: auto;
    margin-bottom: auto;
}


.charityRight {
    flex: 0.5;
}

.charityRight>img {
    margin-left: auto;
    z-index: 1;
}

.charityLeftHeader {
    color: #63342E;
    font-size: 120px;
    line-height: 100px;
    font-weight: bold;
}

.charity-ftr {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 2000px;
    background: #5D2E28;
    padding: 50px 3vw 30px 3vw;
    color: white;
}

.charityLeftExcerpt {
    margin-top: 30px;
    color: #63342E;
    font-size: 24px;
    font-weight: 500;
}

@media (max-width:900px) {
    .charity {
        flex-direction: column !important;
    }

    .charityLeftHeader {
        font-size: 50px !important;
    }

    .charityLeftExcerpt {
        margin-top: 10px !important;
        font-size: 18px !important;
    }

    .charityRight>img {
        height: 50vw !important;
    }
}