.media {
    padding-left: 3vw;
    padding-right: 3vw;
    margin-top: -100px;
    margin-left: auto;
    margin-right: auto;
    max-width: 2000px;
    background: url('../../assets/icons/contactimg.svg');
    background-repeat: no-repeat;
}

.media-exc {
    font-size: 50px;
    display: flex;
    justify-content: center;
}

@media (max-width:990px) {
    .media-heading {
        font-size: 50px !important;
    }

    .media-container {
        grid-template-columns: repeat(auto-fill, 320px) !important;
    }
}

@media (max-width:580px) {
    .media-heading {
        font-size: 35px !important;
    }

    .media-exc {
        font-size: 28px !important;
    }

    .media-container {
        grid-template-columns: none !important;
    }
}



.media-container {
    grid-gap: 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, 400px);
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 30px;
}

.media-item {
    margin-bottom: .5rem;
    display: flex;
    flex-direction: column;
    background: #fff;
    position: relative;
    max-width: 500px;
}



.media-item-img {
    height: 300px;
    object-fit: cover;
}



.media-heading {
    padding-top: 100px;
    font-size: 85px;
    font-weight: bold;
    color: #63342E;
    display: flex;
    justify-content: center;
}