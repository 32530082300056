.header {
    max-width: 2000px;
    margin-left: auto;
    display: flex;
    justify-content: space-between;
    padding-left: 3vw;
    padding-right: 3vw;
    padding-top: 10px;
    padding-bottom: 10px;
    /* background-color: #fee6d7; */
    position: sticky;
    top: 0;
    z-index: 999;
    width: 100%;
    margin-right: auto;
}

.header-active {
    background-color: #fee6d7;
}

.hamb-menu {
    display: none;
}

.header-icon {
    height: 55px;
}


@media (max-width: 900px) {
    .header-right {
        display: none !important;
    }

    .hamb-menu {
        display: flex !important;
    }

    /* .header-icon {
        height: 8vw;
    } */
}

.header-left-menu>ul {
    font-size: 0.8rem;
}

.header-left {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
}

.header-left-menu {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
}

.navLink {
    padding: 10px;
}

.header-right {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
}

.ant-drawer-header {
    background-color: #fee6d7 !important;
}

.ant-drawer-body {
    background-color: #fee6d7 !important;
}

.hamb-menuli {
    display: flex;
    flex-direction: column;
}

.nav-social {
    margin-top: 20px;
    display: flex;
    column-gap: 10px;
}

.nav-img {
    height: 1.5rem;
}